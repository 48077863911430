// Import styles  
import './scss/styles.scss'  

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

// Site stuff
import './js/site'

window.bootstrap = bootstrap;

/*module.exports = {
    bootstrap
};*/
